import React, { useEffect, useState } from 'react';

import {
  NarrowGreetings,
  Structure,
  HowDoesBootcampWork,
  EmploymentsShortDescription,
  Questions,
  Details,
  Schedule,
  Teachers,
  ProjectsAndReviews,
  GraduatesContent,
  ProjectsContent,
  EntranceInterview,
  Atmosphere,
  PaymentAndGroups,
  PaymentContent,
  OfflineGroupsContent,
} from '../widgets';

import { sendYandexMetrikaEvent } from '../helpers/yandexMetrika';
import { parseDate } from '../helpers/lib';
import {
  getCourse,
  getEventData,
  getNearestGroups,
  getProgramGroups,
} from '../api/strapi';
import data from '../pages-fsd/MskJsPage/model/msk.data';

import { useGreetingsQuery } from '../app/queries/greetings.query';
import { useStudyToolsQuery } from '../app/queries/studyTools.query';
import { useEducationImagesQuery } from '../app/queries/educationImg.query';
import { useProjectsQuery } from '../app/queries/projects.query';
import { useScheduleImgQuery } from '../app/queries/scheduleImg.query';
import { useProgramQuery } from '../app/queries/program.query';
import { useEcbWorksQuery } from '../app/queries/howecbworks.query';
import { useRoadmapImagesQuery } from '../app/queries/roadmapImg.query';
import { usePaymentQuery } from '../app/queries/payment.query';
import { useStudentsQuery } from '../app/queries/students.query';
import { useStudentsWorkQuery } from '../app/queries/studentsWork.query';
import { usePnRQuery } from '../app/queries/projectsAndReviews.query';
import { useAboutQuery } from '../app/queries/about.query';

import HeadMaker from '../HeadMaker';
import Layout from '../shared/Layout/Layout';
import ExamPreparation from '../shared/ExamPreparation/ExamPreparation';

export function Head(props) {
  return <HeadMaker {...props} seo={data.seo} />;
}

const mainData = {
  leadPage: 'JS Msk_Основная программа',
  program: 'JS Msk',
  tags: ['мск'],
};

const leadData = {
  question: {
    ...mainData,
    eventName: 'Оставьте ваши контакты для связи',
    leadAction: 'Получить консультацию',
    leadBlock: 'Остались вопросы',
  },
  greeting: {
    ...mainData,
    eventName: 'Оставьте ваши контакты для связи',
    leadAction: 'Получить консультацию',
    leadBlock: 'Топ',
  },
  request: {
    ...mainData,
    eventName: 'Full Stack (Javascript)',
    leadAction: 'Заявка на программу',
    leadBlock: 'Топ',
  },
  loan: {
    ...mainData,
    eventName: 'Full Stack (Javascript)',
    leadAction: 'Заявка на рассрочку',
    leadBlock: 'Стоимость',
  },
  discount: {
    ...mainData,
    eventName: 'Full Stack (Javascript)',
    leadAction: 'Оплатить',
    leadBlock: 'Стоимость',
  },
  groups: {
    ...mainData,
    eventName: 'Full Stack (Javascript)',
    leadAction: 'Заявка на программу',
    leadBlock: 'Потоки',
  },
  structure: {
    ...mainData,
    eventName: 'Получить подробную программу',
    leadBlock: 'Структура программы',
    leadAction: 'Получить подробную программу',
  },
};

const PROGRAM = 'JS';
const LOCATION = 'MSK';

function MskJsPage() {
  const [group, setGroup] = useState({});
  const [groups, setGroups] = useState([]);
  const [isGroupLoading, setIsGroupLoading] = useState(true);
  const [nextEvent, setNextEvent] = useState();
  const [teachers, setTeachers] = useState([]);
  const [prepGroup, setPrepGroup] = useState(null);

  const { greetingsImgs } = useGreetingsQuery();
  const { logosData } = useStudyToolsQuery();
  const { educationImg } = useEducationImagesQuery();
  const { projectsData } = useProjectsQuery();
  const { scheduleImg } = useScheduleImgQuery();
  const { roadmapImg } = useRoadmapImagesQuery();
  const { programImgs } = useProgramQuery();
  const { ecbWorksImgs } = useEcbWorksQuery();
  const { paymentImgs } = usePaymentQuery();
  const { reviewsImgs } = usePnRQuery();
  const { studentsData } = useStudentsQuery();
  const { studentsWorkData } = useStudentsWorkQuery();
  const { aboutImgs } = useAboutQuery();

  useEffect(() => {
    const ac = new AbortController();

    sendYandexMetrikaEvent('mainProgramView');
    getProgramGroups(ac, PROGRAM, LOCATION).then((data) => {
      setGroups(data);
    });

    getProgramGroups(ac, 'PREPARE_JS').then((groups) =>
      setPrepGroup(groups && groups[0]),
    );
    getNearestGroups(ac, PROGRAM, LOCATION)
      .then((data) => {
        setGroup(data);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsGroupLoading(false);
      });

    getEventData('DOD_JS', ac).then(setNextEvent).catch(console.error);
    getCourse(ac, 'msk_js')
      .then((data) => setTeachers(data[0].teacher))
      .catch((e) => console.error(e));

    return () => {
      ac.abort();
    };
  }, []);

  return (
    <Layout leadPage="Москва JS">
      <NarrowGreetings
        {...data.greetings}
        bg="greeting-js"
        labelTheme="paleBlue"
        image={greetingsImgs['greetings/msk-js']}
        extraLeadData={leadData.greeting}
        requestLeadData={leadData.request}
        group={group}
        isLoading={isGroupLoading}
      />
      <Details {...data.details} />
      <Teachers {...data.teachers} teachers={teachers} />
      <Schedule {...data.schedule} images={scheduleImg} />
      <Structure
        {...data.structure}
        bg={'common-programs'}
        leadData={leadData.structure}
        programImgs={programImgs}
        program="js"
      />
      <EmploymentsShortDescription data={data.employmentDescription} />
      <ProjectsAndReviews {...data.projectsAndReviews} images={reviewsImgs}>
        <ProjectsContent {...data.projects} projectsData={projectsData} />
        <GraduatesContent
          {...data.graduates}
          data={{
            people: data.graduates.data.people,
          }}
          studentsData={studentsData}
          studentsWorkData={studentsWorkData}
          noBg
        />
      </ProjectsAndReviews>
      <PaymentAndGroups bg={'paymentgroups'}>
        <PaymentContent
          {...data.payment}
          imgs={paymentImgs}
          group={group}
          loanLeadData={leadData.loan}
          discountLeadData={leadData.discount}
        />
        <OfflineGroupsContent
          {...data.groups}
          groups={groups}
          requestLeadData={leadData.groups}
        />
      </PaymentAndGroups>
      <EntranceInterview {...data.entranceInterview} />
      <ExamPreparation
        {...data.examPrepare}
        startDate={parseDate(prepGroup?.startDate)?.slice(1).join(' ')}
        priceData={prepGroup?.price}
        img={programImgs['program/prepareTitle']}
      />
      <Atmosphere images={aboutImgs} {...data.atmosphere} />
      <HowDoesBootcampWork
        images={ecbWorksImgs}
        {...data.howDoesBootcampWork}
      />
      <Questions {...data.questions} extraLeadData={leadData.question} />
    </Layout>
  );
}

export default MskJsPage;
